/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');
require('./theme/theme');
require('@fortawesome/fontawesome-free/js/all');

require('startbootstrap-sb-admin-2/vendor/datatables/jquery.dataTables');
require('startbootstrap-sb-admin-2/vendor/datatables/dataTables.bootstrap4');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//require('./components/Example');
